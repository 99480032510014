import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Box,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    InputAdornment,
    Divider
} from '@mui/material';
import {
    People,
    Visibility,
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
    Search as SearchIcon,
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell
} from 'recharts';

import { getStats, clearStats } from '../../services/stats';

function DashboardPanel() {
    const [stats, setStats] = useState({
        totalVisits: 0,
        uniqueVisitors: 0,
        last24hVisits: 0,
        visitsByPage: [],
        visitorsTrend: [],
        productVisits: [],
        dailyProductVisits: []
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedAccordion, setExpandedAccordion] = useState(false);
    const [expandedProducts, setExpandedProducts] = useState({});
    const [areAllExpanded, setAreAllExpanded] = useState(false);

    useEffect(() => {
        const fetchVisitStats = async () => {
            try {
                const data = await getStats();
                console.log('Datos de estadísticas:', data);
                setStats({
                    totalVisits: data.totalVisits.total_visits,
                    uniqueVisitors: data.totalVisits.unique_visitors,
                    last24hVisits: data.totalVisits.last_24h_visits,
                    visitsByPage: data.visitsByPage,
                    visitorsTrend: data.visitorsTrend || [],
                    productVisits: data.productVisits || [],
                    dailyProductVisits: data.dailyProductVisits || []
                });
            } catch (error) {
                console.error('Error al obtener estadísticas:', error);
            }
        };

        fetchVisitStats();
        const interval = setInterval(fetchVisitStats, 5 * 60 * 1000);
        
        return () => clearInterval(interval);
    }, []);

    const handleClearStats = async () => {
        try {
            await clearStats();
            setOpenDialog(false);
            const data = await getStats();
            setStats({
                totalVisits: data.totalVisits.total_visits,
                uniqueVisitors: data.totalVisits.unique_visitors,
                last24hVisits: data.totalVisits.last_24h_visits,
                visitsByPage: data.visitsByPage,
                visitorsTrend: data.visitorsTrend || [],
                productVisits: data.productVisits || [],
                dailyProductVisits: data.dailyProductVisits || []
            });
        } catch (error) {
            console.error('Error al limpiar estadísticas:', error);
        }
    };

    const StatCard = ({ title, value, icon, color }) => (
        <Card sx={{ 
            height: '100%',
            backgroundColor: '#fff',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 6px 12px rgba(0,0,0,0.15)'
            }
        }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {React.cloneElement(icon, { 
                        sx: { 
                            fontSize: 40, 
                            color: color,
                            backgroundColor: `${color}15`,
                            p: 1,
                            borderRadius: 2
                        } 
                    })}
                </Box>
                <Typography variant="h4" component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
                    {value}
                </Typography>
                <Typography color="text.secondary">
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );

    // Colores para los gráficos
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

    // Función para formatear los nombres de las rutas
    const formatPageName = (path) => {
        switch (path) {
            case '/':
                return 'Landing Page';
            case '/admin':
                return 'Panel Admin';
            case '/products':
                return 'Productos';
            case '/how-to-buy':
                return 'Cómo Comprar';
            default:
                return path;
        }
    };

    // Transformar los datos con nombres formateados
    const formattedData = stats.visitsByPage.map(page => ({
        name: formatPageName(page.page_path),
        value: parseInt(page.visits),
        originalPath: page.page_path
    }));

    // Agrupar visitas por producto
    const groupedProductVisits = stats.productVisits.reduce((acc, visit) => {
        if (!acc[visit.product_name]) {
            acc[visit.product_name] = [];
        }
        acc[visit.product_name].push(visit);
        return acc;
    }, {});

    // Filtrar productos basado en la búsqueda
    const filteredProducts = Object.keys(groupedProductVisits).filter(productName =>
        productName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleMainAccordionChange = () => {
        setExpandedAccordion(!expandedAccordion);
        // Si el acordeón principal se cierra, cerrar todos los productos
        if (expandedAccordion) {
            setExpandedProducts({});
        }
    };

    const handleProductAccordionChange = (productName) => {
        setExpandedProducts(prev => ({
            ...prev,
            [productName]: !prev[productName]
        }));
    };

    const handleExpandAll = () => {
        if (areAllExpanded) {
            // Cerrar todos
            setExpandedProducts({});
        } else {
            // Abrir todos
            const allExpanded = filteredProducts.reduce((acc, productName) => {
                acc[productName] = true;
                return acc;
            }, {});
            setExpandedProducts(allExpanded);
        }
        setAreAllExpanded(!areAllExpanded);
    };

    const ProductVisitsAccordion = ({ productName, visits, dailyVisits }) => {
        // Filtrar las visitas diarias para este producto
        const productDailyVisits = dailyVisits.filter(v => v.product_name === productName);
        
        return (
            <Accordion
                expanded={!!expandedProducts[productName]}
                onChange={() => handleProductAccordionChange(productName)}
            >
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        '& .MuiAccordionSummary-content': {
                            display: 'grid',
                            gridTemplateColumns: '2fr 1fr 1fr 1fr',
                            gap: 2,
                            width: '100%',
                            alignItems: 'center'
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {productDailyVisits[0]?.main_image && (
                            <img 
                                src={productDailyVisits[0].main_image} 
                                alt={productName}
                                style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 4 }}
                            />
                        )}
                        <Typography>{productName}</Typography>
                    </Box>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Visibility sx={{ fontSize: 18 }} />
                        {visits.reduce((sum, visit) => sum + visit.visit_count, 0)}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>Visitas diarias</Typography>
                        <Box sx={{ 
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                            gap: 1
                        }}>
                            {productDailyVisits.map((dailyVisit, index) => (
                                <Paper
                                    key={index}
                                    sx={{
                                        p: 1,
                                        textAlign: 'center',
                                        backgroundColor: 'rgba(0,0,0,0.02)'
                                    }}
                                >
                                    <Typography variant="caption" display="block">
                                        {new Date(dailyVisit.visit_day).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="h6">
                                        {dailyVisit.visit_count}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        visitas
                                    </Typography>
                                </Paper>
                            ))}
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 4 
            }}>
                <Typography variant="h4" sx={{ color: '#5a4a42' }}>
                    Panel de Control
                </Typography>
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => setOpenDialog(true)}
                >
                    Limpiar Estadísticas
                </Button>
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitas Totales"
                        value={stats.totalVisits}
                        icon={<Visibility />}
                        color="#ff9800"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitantes Únicos"
                        value={stats.uniqueVisitors}
                        icon={<People />}
                        color="#607d8b"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitas Últimas 24 Horas"
                        value={stats.last24hVisits}
                        icon={<Visibility />}
                        color="#ff9800"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StatCard
                        title="Visitas por Página"
                        value={stats.visitsByPage.length}
                        icon={<Visibility />}
                        color="#ff9800"
                    />
                </Grid>
            </Grid>

            {/* Sección de gráficos */}
            <Grid container spacing={3} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            mb: 2 
                        }}>
                            <Typography variant="h6" sx={{ color: '#5a4a42' }}>
                                Estadísticas de Productos
                            </Typography>
                            <Button
                                variant="outlined"
                                startIcon={areAllExpanded ? <ExpandLess /> : <ExpandMore />}
                                onClick={handleExpandAll}
                                size="small"
                            >
                                {areAllExpanded ? 'Colapsar Todo' : 'Expandir Todo'}
                            </Button>
                        </Box>
                        
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Buscar producto..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{ mb: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {filteredProducts.map(productName => (
                            <ProductVisitsAccordion
                                key={productName}
                                productName={productName}
                                visits={groupedProductVisits[productName]}
                                dailyVisits={stats.dailyProductVisits}
                            />
                        ))}
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirmar Limpieza</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar todas las estadísticas? Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>
                        Cancelar
                    </Button>
                    <Button onClick={handleClearStats} color="error" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DashboardPanel; 