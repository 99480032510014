export const recordVisit = async (pagePath) => {
    const normalizedPath = pagePath.split('?')[0];
    const sessionKey = `visit_${normalizedPath}`;
    const currentTime = Date.now();

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}visits/record-visit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pagePath: normalizedPath })
        });

        if (response.ok) {
            sessionStorage.setItem(sessionKey, currentTime.toString());
        }
    } catch (error) {
        console.error('Error al registrar visita:', error);
    }
};

export const getStats = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}visits/stats`);
        if (!response.ok) {
            throw new Error('Error al obtener estadísticas');
        }
        const data = await response.json();
        
        const visitorsTrend = data.visitorsTrend?.map(item => ({
            date: new Date(item.date).toLocaleDateString(),
            totalVisits: parseInt(item.total_visits),
            uniqueVisitors: parseInt(item.unique_visitors)
        })) || [];

        return {
            totalVisits: data.totalVisits,
            visitsByPage: data.visitsByPage,
            visitorsTrend: visitorsTrend,
            productVisits: data.productVisits,
            dailyProductVisits: data.dailyProductVisits
        };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const clearStats = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}visits/clear-stats`, {
            method: 'DELETE',
        });
        
        if (!response.ok) {
            throw new Error('Error al limpiar estadísticas');
        }

        return await response.json();
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};


